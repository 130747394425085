.auth_block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.auth_block_content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: white;
    max-width: 80vw;
    max-height: 70vh;
    padding: 50px 100px;
    border-radius: 10px;
}

.auth_block_input {
    max-width: 250px;
    font-size: 18px;
    padding: 10px;
    border: var(--default_border);
    border-radius: 7px;
}

.auth_block_input::placeholder {
    color: #4d525b33;
}

.auth_block_remember_me {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.auth_block_remember_me_title {
    color: #828282;
}

.auth_block_sign-in {
    padding: 10px;
    max-width: 250px;
    background: white;
    color: var(--linear);
    border: 1px solid var(--linear);
    border-radius: 7px;
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .auth_block_content{
        padding: 30px;
    }
}
