.subdivisions_choice_list_block_row {
    display: flex;
    justify-content: space-between;
    border: 1px solid white;
    padding: 5px 0 5px 10px;
    align-items: center;
}

.subdivisions_choice_list_block_row:hover {
    border: var(--default_border);
    border-radius: 5px;
}

.subdivisions_choice_list_changing_block {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subdivisions_choice_list_block_row_title {
    color: #4d525b;
}

.subdivisions_choice_list_block_row_changing {
    display: "flex";
    justify-content: center;
    align-items: center;
    position: relative;
}

.subdivisions_choice_list_block_row_add,
.subdivisions_choice_list_block_row_cancle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.4s;
}

.subdivisions_choice_list_block_row_cancle {
    opacity: 0;
}
