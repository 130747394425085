.Departments__Arrows {
    position: fixed;
    right: 20px;
    bottom: 5px;
    z-index: 500;
    border-radius: 4px;
}

.Departments__Arrow {
    transition: 0.3s;
    color: var(--animation_color);
}

.Departments__Arrow:hover {
    color: #e7a253b2;
}