.divider {
    border: 1px solid #c4c4c4;
    margin: 15px 0 4px 0;
}

.department_line {
    padding: 0 0 30px;
    transition: 0.5s;
}

@media screen and (max-width: 900px) {
    .department_line {
        padding: 70px 0 30px 0;
    }
}
