.department_block {
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 10px ;
}

.department_block_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    gap: 5px;
    width: 85%;
}

.departments_item {
    width: 140px;
}

.department_block_img {
    position: relative;
    top: -5;
}

.department_text {
    font-size: var(--lineTextSize);
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #00abcd;
    font-weight: 600;
    padding-left: 10px;
}

.departments {
    display: flex;
    gap: 15px;
    width: 100%;
}

.department_header {
    display: flex;
    gap: 3px;
    align-items: center;
}

.department_header_text {
    padding-bottom: 5px;
}

.raunded {
    width: var(--grayTextSize);
    height: var(--grayTextSize);
    border-radius: 50%;
    margin-right: 5px;
}
