.progress_bar {
    width: 100%;
    height: 10px;
}

.progress_bar_animation_color {
    background: var(--animation_color);
    height: 100%;
    width: 100px;
    animation-name: load;
    animation-timing-function: linear;
}

@keyframes load {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
