.employee_name {
    font-size: var(--nameSize);
    line-height: 23px;
    letter-spacing: 0.01em;
    color: var(--default);
}

.top_finished {
    color: var(--green);
}

.top_color_red {
    color: var(--red);
}

.top_color_orange {
    color: var(--orange);
}

.department_employe_counts {
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    line-height: 23px;
    letter-spacing: 0.01em;
}

.department_employe_count_list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.department_employe_count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--coumnWith);
    padding: 6px 0;
    font-size: var(--tableCountSize);
}

.table_applications {
    display: flex;
    justify-content: space-between;
}

.table_applications_implementers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 69px;
}

.table_applications_counts {
    display: flex;
}

.department_header_desc {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 11px;
    letter-spacing: 0.01em;
    color: #828282;
    width: var(--coumnWith);
    padding: 5px 0;
}
