* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    user-select: none;
}

body {
    background: #f3f6f9;
}
body::-webkit-scrollbar {
    display: none;
}

:root {
    --default: #4d525b;
    --dark_gray: #4b4c4d;
    --gray: #828282;
    --red: #e82024;
    --orange: #f3bb1c;
    --linear: #00abcd;
    --green: #3cc13b;
    --animation_color: #4cc6e0;

    --default_border: 1px solid #4d525b33;

    --coumnWith: 150px;

    --nameSize: 28px;
    --tableCountSize: 42px;
    --tableCountSizeMobile: 15px;
    --grayTextSize: 17px;
    --lineTextSize: 21px;
}

.default_color {
    color: var(--default);
}

.red_color {
    color: var(--red);
}

.orange_color {
    color: var(--orange);
}

.linear_color {
    color: var(--linear);
}

.green_color {
    color: var(--green);
}

.dark-gray_color {
    color: var(--dark_gray);
}

.gray_color {
    color: var(--gray);
}

.bgOrange {
    background: var(--orange);
}

.bgRed {
    background: var(--red);
}

.rotate180 {
    transform: rotate(180deg);
}