.updating_block {
    position: relative;
    overflow: hidden;
}

.updating_animate {
    position: absolute;
    top: 0;
    animation: loading infinite 2s;
    height: 100%;
    background: var(--green);
    z-index: -1;
}

@keyframes loading {
    0% {
        left: 0;
        width: 0;
    }
    50% {
        left: 0;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 0;
    }
}

.updating_content {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: changeColor infinite 2s;
    font-size: var(--nameSize);
    padding: 12px 0;
    z-index: 5;
}

.noData {
    animation: none;
}

@keyframes changeColor {
    0% {
        color: black;
    }
    70% {
        color: white;
    }
    100% {
        color: black;
    }
}
