.header_blok {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}

.header {
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

.header_content {
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
}

.header_first_blok {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.header_title {
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.01em;
    text-align: center;
}

.header_title_2 {
    font-size: var(--grayTextSize);
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: center;
}


.header_second_block {
    display: flex;
    gap: 25px;
    align-items: center;
}
.header_applications {
    display: flex;
}

.application_count {
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.01em;
    width: var(--coumnWith);
    text-align: center;
}

.application_count_desc {
    font-size: var(--grayTextSize);
    line-height: 19px;
    letter-spacing: 0.01em;
    width: var(--coumnWith);
    text-align: center;
}

.header_first_blok_back_btn {
    display: none;
}

@media screen and (max-width: 900px) {
    .header_content {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .header_second_block_img {
        display: none;
    }
    .header_blok {
        justify-content: space-between;
    }
    .header_first_blok {
        flex-direction: row;
        gap: 15px;
    }
    .header_first_blok_title {
        display: flex;
        flex-direction: column;
    }
    .header_first_blok_back_btn {
        display: block;
    }

    .application_count,
    .application_count_desc {
        line-height: 10px;
        font-size: 10px;
        position: relative;
        left: 30%;
    }

    .application_counts_mobile {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .header_title {
        font-size: 25px;
    }

    .header_title_2 {
        font-size: 12px;
    }
    .header_title {
        line-height: 13px;
    }
    .header_blok {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        padding: 10px 0;
    }

    .header_second_block {
        padding-right: 15px;
    }
    .header {
        border-radius: 0;
        margin-top: 55px;
    }
}
