.main_mobile {
    overflow: scroll;
    padding-bottom: 25px;
}
.main_mobile::-webkit-scrollbar {
    display: none;
}

.main_mobile_header {
    display: flex;
}

.main_mobile_header_table_row {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    width: 90px;
    border: 1px solid #f3f6f9;
}

.main_mobile_header_table_row_planned {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    width: 140px;
    border: 1px solid #f3f6f9;
}

.main_mobile_header_table_row_name {
    background: white;
    align-items: center;
    color: var(--dark_gray);
    border: 1px solid #f3f6f9;
}

.main_mobile_header_table_row_name_p {
    padding: 6px 0;
    height: 37px;
    overflow: hidden;
    padding-left: 10px;
    font-size: 14px;
    border: 1px solid #f3f6f9;
}

.minWidth290 {
    min-width: 290px;
}

.main_mobile_header_table_row_title {
    font-weight: 400;
    font-size: 14px;
}

.department_employe_count_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    height: 37px;
    border: 1px solid #f3f6f9;
}

.department_employe_count_mobile > p {
    font-size: 18px;
}

.department_employe_counts_mobile {
    border-radius: 20px;
    padding: 0 7px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    line-height: 23px;
    letter-spacing: 0.01em;
}

.main_mobile_footer {
    display: flex;
    gap: 10px;
    padding: 3px 0 3px 10px;
    align-items: center;
    width: 200vw;
}
