.choice_header {
    background: white;
    display: flex;
    padding: 25px 5vw;
    justify-content: space-between;
}

.choice_subdivision_title {
    color: #c53036;
    font-size: 28px;
    font-weight: 700;
}

.choice_subdivision_btns {
    display: flex;
    gap: 10px;
}

.subdivisions_choice_list {
    display: flex;
    justify-content: space-between;
    padding: 50px 5vw;
}

.subdivisions_choice_column {
    display: flex;
    flex-direction: column;
    width: 32%;
    row-gap: 15px;
}

.choice_subdivision_btn_reset,
.choice_subdivision_btn_choiceAll,
.choice_subdivision_btn_show {
    padding: 11px 16px;
    border-radius: 5px;
    transition: 0.4s;
    cursor: pointer;
}
.choice_subdivision_btn_reset:hover,
.choice_subdivision_btn_choiceAll:hover,
.choice_subdivision_btn_show:hover {
    transform: scale(1.1);
}

.choice_subdivision_btn_logout {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.choice_subdivision_btn_reset {
    background: #e34a4e;
    color: white;
    border: 0;
}

.choice_subdivision_btn_choiceAll {
    background: white;
    border: 2px solid #f4f6f9;
}

.choice_subdivision_btn_show {
    background: #4fc6e0;
    color: white;
    border: 0;
}

.subdivision_choice_item {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    padding: 15px 0;
    cursor: pointer;
}

.subdivisions_choice_icon {
    position: relative;
    width: 95%;
    text-align: center;
}

.subdivision_choice_item_icon_img {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subdivisions_subdivisions_count,
.subdivisions_choice_btn_close,
.subdivisions_choice_count_choiced {
    position: absolute;
    background: #f4f6f9;
    border-radius: 8px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.subdivisions_subdivisions_count {
    right: 0;
    top: 0;
}

.subdivisions_choice_btn_close {
    transform: rotateY(45deg);
    right: 0;
    top: 0;
}

.subdivisions_choice_count_choiced {
    right: 30px;
}

.subdivision_choice_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subdivisions_choice_title_block {
    position: relative;
    width: 95%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.subdivisions_choice_burger {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0, -50%);
    transition: 0.4s;
}

.subdivisions_choice_burger_white {
}
.subdivisions_choice_burger_black {
    z-index: -1;
}

.subdivisions_choice_title {
    background: #4fc6e0;
    color: white;
    width: 100%;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: 0.4s;
    padding: 1px 0 1px 8px;
}

.subdivisions_choice_title_btn_add_block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    border: var(--default_border);
    border-radius: 5px;
}

.subdivisions_choice_title_btn_add {
    transition: 0.4s;
}

.subdivisions_choice_for_open_list {
    height: 0;
    transition: 0.5s;
    width: 95%;
}

.subdivisions_choice_list_block {
    padding-top: 15px;
    position: relative;
    opacity: 0;
    transition: 0.4s;
}
@media screen and (max-width: 767px) {
    .subdivisions_choice_list {
        flex-direction: column;
        gap: 15px;
        padding: 50px 25px 90px 25px;
    }
    .subdivisions_choice_column {
        width: 100%;
    }
    .choice_subdivision_btns {
        z-index: 1;
        position: fixed;
        background: white;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 15px 0;
        justify-content: center;
    }

    .choice_header {
        padding: 25px;
    }
}
