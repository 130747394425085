.main {
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 10px 10px;
    padding-bottom: 10px;
}

.implementers_list {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.subtitles_gray {
    font-size: var(--grayTextSize);
    letter-spacing: 0.01em;
    color: #828282;
}

@media screen and (max-width: 900px) {
    .main {
        overflow: scroll;
    }
}
